// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class KickGuestReducer extends Reducer
{
	public static reducerName: string = "KickGuest";
	public static call(_address: Address) {
		this.getReducer().call(_address);
	}

	public call(_address: Address) {
		const serializer = this.client.getSerializer();
		let _addressType = AlgebraicType.createProductType([
			new ProductTypeElement("addressBytes", AlgebraicType.createArrayType(AlgebraicType.createPrimitiveType(BuiltinType.Type.U8))),
		]);
		serializer.write(_addressType, _address);
		this.client.call("KickGuest", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let addressType = AlgebraicType.createProductType([
			new ProductTypeElement("addressBytes", AlgebraicType.createArrayType(AlgebraicType.createPrimitiveType(BuiltinType.Type.U8))),
		]);
		let addressValue = AlgebraicValue.deserialize(addressType, adapter.next())
		let address = new Address(addressValue.asProductValue().elements[0].asBytes());
		return [address];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _address: Address) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _address: Address) => void)
	{
		this.client.on("reducer:KickGuest", callback);
	}
}


export default KickGuestReducer
