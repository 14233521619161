// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, SumType, SumTypeVariant, DatabaseTable, AlgebraicValue, ReducerEvent, Identity, Address, ClientDB, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";
// @ts-ignore
import { DataType } from "./data_type";

export class ElementData extends DatabaseTable
{
	public static db: ClientDB = __SPACETIMEDB__.clientDB;
	public static tableName = "ElementData";
	public id: number;
	public name: string;
	public dataType: DataType;
	public data: string;
	public byteArray: Uint8Array | null;
	public dataWidth: number;
	public dataHeight: number;
	public createdBy: string;

	public static primaryKey: string | undefined = "id";

	constructor(id: number, name: string, dataType: DataType, data: string, byteArray: Uint8Array | null, dataWidth: number, dataHeight: number, createdBy: string) {
	super();
		this.id = id;
		this.name = name;
		this.dataType = dataType;
		this.data = data;
		this.byteArray = byteArray;
		this.dataWidth = dataWidth;
		this.dataHeight = dataHeight;
		this.createdBy = createdBy;
	}

	public static serialize(value: ElementData): object {
		return [
		value.id, value.name, DataType.serialize(value.dataType), value.data, value.byteArray ? { "some": Array.from(value.byteArray) } : { "none": [] }, value.dataWidth, value.dataHeight, value.createdBy
		];
	}

	public static getAlgebraicType(): AlgebraicType
	{
		return AlgebraicType.createProductType([
			new ProductTypeElement("id", AlgebraicType.createPrimitiveType(BuiltinType.Type.U32)),
			new ProductTypeElement("name", AlgebraicType.createPrimitiveType(BuiltinType.Type.String)),
			new ProductTypeElement("dataType", DataType.getAlgebraicType()),
			new ProductTypeElement("data", AlgebraicType.createPrimitiveType(BuiltinType.Type.String)),
			new ProductTypeElement("byteArray", AlgebraicType.createSumType([
			new SumTypeVariant("some", AlgebraicType.createArrayType(AlgebraicType.createPrimitiveType(BuiltinType.Type.U8))),
			new SumTypeVariant("none", AlgebraicType.createProductType([
		])),
		])),
			new ProductTypeElement("dataWidth", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("dataHeight", AlgebraicType.createPrimitiveType(BuiltinType.Type.I32)),
			new ProductTypeElement("createdBy", AlgebraicType.createPrimitiveType(BuiltinType.Type.String)),
		]);
	}

	public static fromValue(value: AlgebraicValue): ElementData
	{
		let productValue = value.asProductValue();
		let __Id = productValue.elements[0].asNumber();
		let __Name = productValue.elements[1].asString();
		let __DataType = DataType.fromValue(productValue.elements[2]);
		let __Data = productValue.elements[3].asString();
		let __ByteArray = productValue.elements[4].asSumValue().tag == 1 ? null : productValue.elements[4].asSumValue().value.asBytes();
		let __DataWidth = productValue.elements[5].asNumber();
		let __DataHeight = productValue.elements[6].asNumber();
		let __CreatedBy = productValue.elements[7].asString();
		return new this(__Id, __Name, __DataType, __Data, __ByteArray, __DataWidth, __DataHeight, __CreatedBy);
	}

	public static *filterById(value: number): IterableIterator<ElementData>
	{
		for (let instance of this.db.getTable("ElementData").getInstances())
		{
			if (instance.id === value) {
				yield instance;
			}
		}
	}

	public static findById(value: number): ElementData | undefined
	{
		return this.filterById(value).next().value;
	}

	public static *filterByName(value: string): IterableIterator<ElementData>
	{
		for (let instance of this.db.getTable("ElementData").getInstances())
		{
			if (instance.name === value) {
				yield instance;
			}
		}
	}

	public static *filterByData(value: string): IterableIterator<ElementData>
	{
		for (let instance of this.db.getTable("ElementData").getInstances())
		{
			if (instance.data === value) {
				yield instance;
			}
		}
	}

	public static *filterByDataWidth(value: number): IterableIterator<ElementData>
	{
		for (let instance of this.db.getTable("ElementData").getInstances())
		{
			if (instance.dataWidth === value) {
				yield instance;
			}
		}
	}

	public static *filterByDataHeight(value: number): IterableIterator<ElementData>
	{
		for (let instance of this.db.getTable("ElementData").getInstances())
		{
			if (instance.dataHeight === value) {
				yield instance;
			}
		}
	}

	public static *filterByCreatedBy(value: string): IterableIterator<ElementData>
	{
		for (let instance of this.db.getTable("ElementData").getInstances())
		{
			if (instance.createdBy === value) {
				yield instance;
			}
		}
	}


}

export default ElementData;
