// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class UpdateGuestSelectedLayoutReducer extends Reducer
{
	public static reducerName: string = "UpdateGuestSelectedLayout";
	public static call(_selectedLayoutId: number) {
		this.getReducer().call(_selectedLayoutId);
	}

	public call(_selectedLayoutId: number) {
		const serializer = this.client.getSerializer();
		let _selectedLayoutIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_selectedLayoutIdType, _selectedLayoutId);
		this.client.call("UpdateGuestSelectedLayout", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let selectedLayoutIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let selectedLayoutIdValue = AlgebraicValue.deserialize(selectedLayoutIdType, adapter.next())
		let selectedLayoutId = selectedLayoutIdValue.asNumber();
		return [selectedLayoutId];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _selectedLayoutId: number) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _selectedLayoutId: number) => void)
	{
		this.client.on("reducer:UpdateGuestSelectedLayout", callback);
	}
}


export default UpdateGuestSelectedLayoutReducer
